import { useMemo } from 'react';
import { Box, Button, VStack, Textarea, Input } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Select, Radio } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const purpose_of_your_business_relationship =
  'Please provide the purpose of your business relationship with Keyrock';
const purpose_of_your_business_relationship_options = [
  'Market Making',
  'OTC/OEX',
];
const is_token_issuer = 'Are you the issuer of this token';
//questions triggered if 'is_token_issuer' = 'Yes'
const token_classification = 'How is the token classified';
const token_classification_options = [
  'Payment token',
  'Utility token',
  'Security token',
  'Other',
];
const token_classification_other = 'token_classification_other';
const whitepaper_available = 'Do you have a Whitepaper available for the token';
const token_status_legal_opinion =
  'Do you have a legal opinion/letter on the status of the token';
const no_token_status_legal_opinion_explained =
  'Kindly clarify why the opinion is not available, and whether we can expect it to be available in the future';

//questions for both options
const source_of_funds = 'What is the source of your funds?';

const provided_bank_details = 'Please provide your bank details';

const source_of_wealth = 'Please indicate the source of wealth of your company';

const validationSchema = Yup.object({
  [purpose_of_your_business_relationship]: Yup.string()
    .oneOf(purpose_of_your_business_relationship_options)
    .required()
    .label('This'),
  [is_token_issuer]: Yup.string().oneOf(['Yes', 'No']).required().label('This'),
  [token_classification]: Yup.string().when(is_token_issuer, {
    is: (val: string) => val === 'Yes',
    then: (schema) =>
      schema.oneOf(token_classification_options).required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [token_classification_other]: Yup.string().when(token_classification, {
    is: (val: string) => val === 'Other',
    then: (schema) => schema.label('This field').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [whitepaper_available]: Yup.string().when(is_token_issuer, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.oneOf(['Yes', 'No']).required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [token_status_legal_opinion]: Yup.string().when(is_token_issuer, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.oneOf(['Yes', 'No']).required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [no_token_status_legal_opinion_explained]: Yup.string().when(
    token_status_legal_opinion,
    {
      is: (val: string) => val === 'No',
      then: (schema) => schema.required().label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [source_of_funds]: Yup.string().label('This field').required(),
  [provided_bank_details]: Yup.string().label('This field').required(),
  [source_of_wealth]: Yup.string().label('This field').required(),
});

export const BusinessRelationship = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [purpose_of_your_business_relationship]:
        metadata?.[purpose_of_your_business_relationship] || '',
      [is_token_issuer]: metadata?.[is_token_issuer] || '',
      [token_classification]: metadata?.[token_classification] || '',
      [token_classification_other]:
        metadata?.[token_classification_other] || '',
      [whitepaper_available]: metadata?.[whitepaper_available] || '',
      [token_status_legal_opinion]:
        metadata?.[token_status_legal_opinion] || '',
      [no_token_status_legal_opinion_explained]:
        metadata?.[no_token_status_legal_opinion_explained] || '',
      [provided_bank_details]: metadata?.[provided_bank_details] || '',
      [source_of_funds]: metadata?.[source_of_funds] || '',
      [source_of_wealth]: metadata?.[source_of_wealth] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={purpose_of_your_business_relationship}
          label={t(
            `steps.${stepId}.${purpose_of_your_business_relationship}.label`,
          )}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={purpose_of_your_business_relationship}
              onChange={(value: string) => {
                resetField(token_classification);
                resetField(token_classification_other);
                resetField(whitepaper_available);
                resetField(token_status_legal_opinion);
                resetField(no_token_status_legal_opinion_explained);
                setValue(purpose_of_your_business_relationship, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={purpose_of_your_business_relationship_options}
              defaultValue={f.value}
            />
          )}
        />

        <GroupController
          name={is_token_issuer}
          label={t(`steps.${stepId}.${is_token_issuer}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={is_token_issuer}
              onChange={(value: string) => {
                resetField(token_classification);
                resetField(token_classification_other);
                resetField(whitepaper_available);
                resetField(token_status_legal_opinion);
                resetField(no_token_status_legal_opinion_explained);
                setValue(is_token_issuer, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={['Yes', 'No']}
              defaultValue={f.value}
            />
          )}
        />
        {watch(is_token_issuer) === 'Yes' && (
          <>
            <GroupController
              name={token_classification}
              label={t(`steps.${stepId}.${token_classification}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Select
                  stepId={stepId}
                  name={token_classification}
                  onChange={(value: string) => {
                    resetField(token_classification_other);
                    setValue(token_classification, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={token_classification_options}
                  defaultValue={f.value}
                  hasOtherOption={false}
                />
              )}
            />

            {watch(token_classification) === 'Other' && (
              <GroupController
                name={token_classification_other}
                label={t(`steps.${stepId}.${token_classification_other}.label`)}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
            )}

            <GroupController
              name={whitepaper_available}
              label={t(`steps.${stepId}.${whitepaper_available}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={whitepaper_available}
                  onChange={(value: string) => {
                    setValue(whitepaper_available, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['Yes', 'No']}
                  defaultValue={f.value}
                />
              )}
            />

            <GroupController
              name={token_status_legal_opinion}
              label={t(`steps.${stepId}.${token_status_legal_opinion}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={token_status_legal_opinion}
                  onChange={(value: string) => {
                    resetField(no_token_status_legal_opinion_explained);
                    setValue(token_status_legal_opinion, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['Yes', 'No']}
                  defaultValue={f.value}
                />
              )}
            />

            {watch(token_status_legal_opinion) === 'No' && (
              <GroupController
                name={no_token_status_legal_opinion_explained}
                label={t(
                  `steps.${stepId}.${no_token_status_legal_opinion_explained}.label`,
                )}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
            )}
          </>
        )}

        <GroupController
          name={provided_bank_details}
          label={t(`steps.${stepId}.${provided_bank_details}.label`)}
          helper={t(`steps.${stepId}.${provided_bank_details}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Textarea maxW="400px" resize="vertical" {...f} />;
          }}
        />

        <GroupController
          name={source_of_funds}
          label={t(`steps.${stepId}.${source_of_funds}.label`)}
          helper={t(`steps.${stepId}.${source_of_funds}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Textarea maxW="400px" resize="vertical" {...f} />;
          }}
        />

        <GroupController
          name={source_of_wealth}
          label={t(`steps.${stepId}.${source_of_wealth}.label`)}
          helper={t(`steps.${stepId}.${source_of_wealth}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Textarea maxW="400px" resize="vertical" {...f} />;
          }}
        />

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
